<template>
   
        <div class="px-3 md:px-0 pt-4">

            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6">
                Daftar Tagihan Pembayaran
            </p>

            <router-link :to="{name: 'TagihanDetail', params : {'id_paket_user' : data.id_paket_user }}" class="flex flex-col bg-white py-2 mb-3 px-3 transition-colors cursor-pointer  rounded-lg shadow-md" :key="index" v-for="(data, index) in paketUserList">
                <div class="flex items-center w-full">
                    <div class="w-16">
                        <div class="h-16 w-16 rounded-lg content-center flex items-center text-center bg-yellow-200">
                            <img src="@/assets/images/credit-card.svg" class="w-10 h-10 mx-auto" />
                        </div>
                    </div>
                    <div class="flex-grow ml-3 pt-1 pb-1">
                        <h2 class="font-semibold tracking-tight text-gray-800 text-lg">{{ data.paket.nama_paket }}</h2>
                        <p class="text-gray-600 text-sm">{{ data.created_at | toHumanDate2 }}</p>
                    </div>
                </div>
                <div class="flex mt-3">
                    <span class="text-left text-green-500 text-sm">Jumlah Tagihan</span>
                    <span class="flex-grow text-right space-x-1">
                        <span class="">Rp.</span> 
                        <span class="font-semibold">{{ data.total_tagihan | moneyFormat }}</span>
                    </span>
                </div>
            </router-link>

            <div class="-mx-3 md:mx-0">
                <div class="animate-pulse" v-if="isLoading">
                    <div class="flex bg-white py-2 px-3 rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                        <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                        </div>
                        <div class="flex-grow ml-3 pt-1 pb-1">
                            <div class="h-6 bg-gray-100 rounded"></div>
                            <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                            <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>

           

            <div v-if="!isLoading && paketUserList.length == 0" class="text-center w-full mt-6" >
                <div class="w-2/3 px-5 mx-auto">
                    <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                    <p class="mt-1 text-gray-800 font-semibold">Belum ada tagihan untuk dibayar</p>
                </div>
            </div>

        </div>
</template>
<script>
import { combineObject, errorHandler, showErrorNotif } from '../../helpers/Tools';
export default {
    name : 'TagihanIndex',
    data(){
        return {
            isLoading    : false,
            paketUserList: [],
            page         : 1
        }
    },
    mounted() {
        this.getTagihan();
        this.scroll();
    },
    destroyed() {
        window.onscroll = () => {}
    },
    methods : {
        scroll() {
            window.onscroll = () => {
                const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                if (bottomOfWindow && !this.isEmpty) {
                    this.getTagihan()
                }
            }
        },
        async getTagihan() {
           this.isLoading = true;
            try {
                let res = await this.$store.dispatch("getDaftarTagihanPaket", {
                    page        : this.page
                });
                this.isLoading = false;
                let result = res.data;
                if(result.status == 'success') {
                    if(result.data.data.length == 0) {
                        this.isEmpty = true;
                    }

                    if(this.page == 1){
                        this.paketUserList = result.data.data;
                    }else{
                        this.paketUserList = combineObject(this.paketUserList, result.data.data, 'id_paket_user');
                    }
                    this.page++;

                } else {
                    this.isEmpty = true;
                    showErrorNotif(this, result.message);
                }
            } catch (error) {
                this.isLoading = false;
                errorHandler(this, error);
            }
       }
    }
}
</script>